import clsx from 'clsx'

export const variants = {
  center: 'text-center',
  left: 'text-left',
  'left-mobile': 'text-left lg:text-center',
  smaller: 'text-center lg:!text-4xl 2xl:!text-5xl',
}

const H1 = ({ children, variant = 'center' }) => {
  return (
    <h1
      className={clsx(
        'text-2xl font-bold text-primary-500',
        'md:text-4xl',
        'lg:mx-auto lg:max-w-2/3 lg:text-4xl',
        '2xl:text-6xl',
        variants[variant]
      )}
    >
      {children}
    </h1>
  )
}

export default H1
