import PostCard from 'components/PostCard'

const IndexSlider = ({ posts, showType = true }) => {
  return (
    <div className="index-slider-wrap hide-scroll-bar flex overflow-x-scroll pb-2 lg:container">
      <div className="index-slider flex flex-nowrap lg:grid lg:grid-cols-4 lg:gap-4">
        {posts &&
          !!posts.length &&
          posts.map((post) => (
            <div key={post._id} className="inline-block px-2 lg:block lg:px-0">
              <PostCard post={post} showType={showType} />
            </div>
          ))}
      </div>
    </div>
  )
}
export default IndexSlider
