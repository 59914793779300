import { GrBlockQuote } from 'react-icons/gr'

const Blockquote = ({ cite, url, children }) => {
  return (
    <figure className="mx-auto max-w-3xl py-20" cite={url}>
      <div className="flex justify-center text-4xl text-accent-200">
        <GrBlockQuote />
      </div>
      <blockquote className="mb-10 text-center text-xl italic leading-normal text-accent-500 lg:text-2xl 2xl:text-4xl">
        {children}
      </blockquote>
      {cite && (
        <figcaption className="text-center text-base lg:text-xl">
          {url ? (
            <a href={url} target="_blank" rel="noreferrer">
              {cite}
            </a>
          ) : (
            cite
          )}
        </figcaption>
      )}
    </figure>
  )
}

export default Blockquote
