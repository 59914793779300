import Link from 'next/link'
import clsx from 'clsx'

export const variants = {
  primary: 'bg-primary-500 text-white hover:bg-primary-600 hover:text-white',
  secondary: 'bg-accent-500 text-white hover:bg-accent-600 hover:text-white',
}

const ButtonLink = ({
  href,
  as,
  locale,
  children,
  target,
  variant = 'primary',
}) => {
  const linkProps = {
    href,
  }
  if (as) {
    linkProps.as = as
  }
  if (target) {
    linkProps.target = target
    linkProps.rel = 'noreferrer'
  }
  if (locale) {
    linkProps.locale = locale
  }
  return (
    <Link {...linkProps}>
      <a
        className={clsx(
          'flex items-center justify-center rounded-full border border-transparent px-8 py-3 text-center text-base font-medium',
          'md:py-3 md:px-10 md:text-lg',
          variants[variant]
        )}
      >
        {children}
      </a>
    </Link>
  )
}

export default ButtonLink
