import { PortableText } from '@portabletext/react'
import client from '@lib/sanity'
import imageUrlBuilder from '@sanity/image-url'
import Image from 'next/image'

const portableTextComponents = {
  list: {
    bullet: ({ children }) => <ul className="pl-5">{children}</ul>,
  },
  listItem: {
    bullet: ({ children }) => (
      <li className="text-accent leading-tight">
        <div className="text-primary-500">{children}</div>
      </li>
    ),
  },
}

const PageBlock = ({ block }) => {
  return (
    <div className="border-b border-primary-100 pb-4 md:pb-8 lg:pb-10">
      {block?.mainImage && (
        <div className="mb-4">
          <div className="relative aspect-[7/4] w-full">
            <Image
              src={imageUrlBuilder(client)
                .image(block.mainImage)
                .width(600)
                .url()}
              alt={block.title}
              layout="fill"
            />
          </div>
          {block.mainImageCredit && (
            <p className="mt-1 text-sm text-primary-300">
              {block.mainImageCreditLink ? (
                <a
                  href={block.mainImageCreditLink}
                  target="_blank"
                  rel="noreferrer"
                  className="border-b-2 border-accent-400 text-primary-300 hover:text-primary-600"
                >
                  {block.mainImageCredit}
                </a>
              ) : (
                block.mainImageCredit
              )}
            </p>
          )}
        </div>
      )}
      <h3 className="mb-4 text-2xl font-bold">{block.title}</h3>
      <div className="prose">
        <PortableText value={block.body} components={portableTextComponents} />
      </div>
    </div>
  )
}
export default PageBlock
