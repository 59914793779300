import Link from 'next/link'
import client from '@lib/sanity'
import imageUrlBuilder from '@sanity/image-url'
import Image from 'next/image'
import { HiDocumentText } from 'react-icons/hi'
import TextTruncate from 'react-text-truncate'

const PageCard = ({ page }) => {
  return (
    <article className="w-full">
      <Link href="/[[...slug]]" as={`/${page.slug.current}`}>
        <a className="block">
          <div className="flex aspect-square w-full flex-col overflow-hidden rounded border border-primary-500 bg-primary-500 shadow">
            <div className="aspect-video">
              <div className="relative h-full w-full bg-primary-500">
                {page.mainImage && page.mainImage.asset ? (
                  <Image
                    src={imageUrlBuilder(client)
                      .image(page.mainImage)
                      .width(600)
                      .url()}
                    alt={page.title}
                    layout="fill"
                    objectFit="contain"
                    objectPosition="center top"
                  />
                ) : (
                  <div className="content-stretch flex h-full items-center justify-center text-6xl text-primary-100">
                    <HiDocumentText />
                  </div>
                )}
              </div>
            </div>
            <div className="content-stretch flex h-1/2 flex-col justify-between">
              <h3 className="p-2 font-medium leading-tight text-white lg:text-xl 2xl:text-xl">
                <TextTruncate
                  line={3}
                  element="span"
                  truncateText="…"
                  text={page.title}
                />
              </h3>
            </div>
          </div>
        </a>
      </Link>
    </article>
  )
}

export default PageCard
