import { getClient } from '@lib/sanity'
import { loadTranslation } from '@lib/i18n'
import { groq } from 'next-sanity'
import { format } from 'date-fns'
import Post from 'components/Post'
import Home from 'components/Home'
import Page from 'components/Page'

const PageComponent = ({ pageData, docType, posts }) => {
  switch (docType) {
    case 'post':
      return <Post post={pageData} />

    case 'home':
      return <Home data={pageData} posts={posts} />

    default:
      return <Page page={pageData} />
  }
}

const getQueryFromSlug = (slugArray = [], locale) => {
  let docType = 'page'
  const docQuery = {
    post: groq`*[_type == "post" && slug.current == $slug && locale == $locale && dateTime(publishedAt) <= dateTime(now())][0]{_id, title, _type, type, mainImage, excerpt, body, publishedAt, source, credit, eventStart, eventEnd, eventLocation, authors[]->{_id, _type, name, slug, mainImage}, downloads[]{_key, label, 'downloadUrl': asset->url}, regions[]->, locale, slug, subjects[]->, relatedProfiles[]->{_id, _type, name, slug, mainImage}, translations[]->{_id, type, slug, locale, publishedAt}}`,
    page: groq`*[_type == "page" && slug.current == $slug && locale == $locale && dateTime(publishedAt) <= dateTime(now())][0]{..., parent->{title, slug}, translations[]->{_id, type, slug, locale, publishedAt}}`,
  }

  if (slugArray.length === 0) {
    return {
      docType: 'home',
      queryParams: {},
      query: docQuery.home,
    }
  }

  const [slugStart] = slugArray

  // We now have to re-combine the slug array to match our slug in Sanity.
  let queryParams = { slug: '', locale, docType: 'page' }

  // Keep extending this section to match the slug against the docQuery object keys
  const postTypes = ['news', 'publications', 'events', 'blog', 'media', 'press']
  if (postTypes.includes(slugStart)) {
    docType = `post`
    queryParams.slug = slugArray[slugArray.length - 1]
  } else {
    queryParams.slug = slugArray.join('/')
  }

  return {
    docType,
    queryParams,
    query: docQuery[docType],
  }
}

export async function getStaticPaths({ locales }) {
  // Page paths
  const pageQueries = await getClient().fetch(
    groq`*[_type in ["page"] && defined(slug.current)]{slug, locale}`
  )

  const pagePaths = pageQueries.map((page) => {
    return {
      params: {
        slug: page.slug.current.split('/').filter((p) => p),
        locale: page.locale || 'en',
      },
    }
  })

  const postQueries = await getClient().fetch(
    groq`*[_type in ["post"] && defined(slug.current) && dateTime(publishedAt) <= dateTime(now())] | order(publishedAt desc)[0...100] {slug, type, locale, publishedAt}`
  )

  const postPaths = postQueries.map((page) => {
    const publishedAt = new Date(page.publishedAt)
    const slug = [
      page.type,
      format(publishedAt, 'yyyy'),
      format(publishedAt, 'MM'),
      page.slug.current,
    ]
    return {
      params: {
        slug, //: slug.filter((p) => p),
        locale: page.locale || 'en',
      },
    }
  })

  const homePaths = locales.map((locale) => ({
    params: {
      slug: null,
      locale,
    },
  }))
  return {
    paths: [...pagePaths, ...postPaths, ...homePaths],
    fallback: 'blocking',
  }
}

export async function getStaticProps({ locale, params }) {
  const translation = await loadTranslation(
    locale,
    process.env.NODE_ENV === 'production'
  )

  const client = getClient()

  let query, queryParams, docType, pageData
  let posts = []

  if (params.slug) {
    // A helper function to work out what query we should run based on this slug
    ;({ query, queryParams, docType } = getQueryFromSlug(params.slug, locale))

    // Get the initial data for this page, using the correct query
    pageData = await client.fetch(query, queryParams)
  } else {
    // Home page
    docType = 'home'
    query = [
      `"pageData": *[_type == "homepage" && locale == "${locale}"][0]{..., themes[]{..., linksTo->{slug, locale}}, resources[]->}`,
      `"posts": *[_type == "post" && locale == "${locale}" && dateTime(publishedAt) <= dateTime(now()) && defined(publishedAt)] | score(sticky == true) | order(_score desc, publishedAt desc)[0...5]`,
      `"news": *[_type == "post" && locale == "${locale}" && dateTime(publishedAt) <= dateTime(now()) && defined(publishedAt) && type in ["news", "events"]] | order(publishedAt desc)[0...8]`,
      `"publications": *[_type == "post" && locale == "${locale}" && dateTime(publishedAt) <= dateTime(now()) && defined(publishedAt) && type == "publications"] | order(publishedAt desc)[0...3]`,
      `"totalPublications": count(*[_type == "post" && type == "publications" && locale == "${locale}" && dateTime(publishedAt) <= dateTime(now()) && defined(publishedAt) && ("organisation_international-drug-policy-consortium-idpc" in relatedProfiles[]._ref || "organisation_international-drug-policy-consortium-idpc" in authors[]._ref)])`,
      `"totalMembers": count(*[_type == "member"])`,
    ]
    queryParams = { locale }
    const data = await client.fetch(
      groq`{
      ${query.join(',\n')}
    }`,
      queryParams
    )

    if (data) {
      pageData = data.pageData ? data.pageData : {}
      posts = data.posts ? data.posts : []
      pageData.news = data.news ? data.news : []
      pageData.publications = data.publications ? data.publications : []
      pageData.totalPublications = data.totalPublications
        ? data.totalPublications
        : 0
      pageData.totalMembers = data.totalMembers ? data.totalMembers : 0
    }
  }

  if (pageData) {
    if (!pageData.translations) {
      pageData.translations = []
    }

    // See if we need to get parent translation
    // order by locale to ensure English comes first (en/es/fr)
    const parentTranslationQuery = groq`*[_type == $type && $id in translations[]._ref] | order(locale asc)[0]{_id, locale, publishedAt, slug, type, translations[]->{_id, locale, publishedAt, _type, type, slug}}`
    const parentTranslations = await client.fetch(parentTranslationQuery, {
      id: pageData._id,
      type: pageData._type,
    })
    if (parentTranslations) {
      pageData.translations = [
        ...pageData.translations,
        parentTranslations,
        ...parentTranslations.translations,
      ]
    }

    // Get child pages
    const childPagesQuery = groq`*[_type == $type && parent._ref == $id && dateTime(publishedAt) <= dateTime(now())]{_id, locale, publishedAt, slug, title, mainImage } | order(lower(title) asc)`
    const childPages = await client.fetch(childPagesQuery, {
      id: pageData._id,
      type: pageData._type,
    })
    if (childPages) {
      pageData.childPages = childPages
    }

    // Do we need to get a feed of related by topic?
    if (pageData.subject) {
      const referencesArr = pageData.subject.map((ref) => ref._ref)
      const relatedQuery = groq`*[_type == "post" && locale == $locale && dateTime(publishedAt) <= dateTime(now()) && type in ["news", "events", "publications", "press", "blog"] && references($referencesArr)] | order(publishedAt desc)[0...8]`
      const relatedPosts = await client.fetch(relatedQuery, {
        locale,
        referencesArr,
      })
      pageData.related = relatedPosts
    }
  } else {
    return {
      notFound: true,
    }
  }
  return {
    props: {
      translation,
      query: query || null,
      queryParams: queryParams || {},
      docType: docType || '404',
      pageData: pageData || {},
      posts,
    },
    revalidate: 60 * 5,
  }
}

export default PageComponent
