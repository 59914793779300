import { t } from '@lingui/macro'

const PostType = ({ post }) => {
  const postTypes = {
    publications: t`Publications`,
    news: t`News`,
    blog: t`Blog`,
    events: t`Events`,
    media: t`IDPC in the Media`,
    press: t`Press Releases`,
  }
  return <span>{postTypes[post.type]}</span>
}
export default PostType
