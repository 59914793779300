import Image from 'next/image'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import Head from 'next/head'
import Link from 'next/link'
import { PortableText } from '@portabletext/react'
import { defaultComponents } from '@lib/portableText'
import imageUrlBuilder from '@sanity/image-url'
import client from '@lib/sanity'
import { Trans } from '@lingui/macro'
import clsx from 'clsx'
import { FaArrowRight } from 'react-icons/fa'
import { useTranslations } from 'providers/TranslationsProvider'
import ButtonLink from 'components/ButtonLink'
import Blockquote from 'components/Blockquote'
import PostTeaser from 'components/PostTeaser'
import IdpcLogo from '../../public/svg/idpcLogo.svg'
import MembersIcon from '../../public/svg/members.svg'
import PublicationsIcon from '../../public/svg/publications.svg'
import MemberHighlight from 'components/MemberHighlight'
import Hr from 'components/ui/Hr'
import H2 from 'components/ui/H2'
import IndexSlider from 'components/IndexSlider'
import FacebookLogo from '../../public/svg/facebookLogo.svg'
import LinkedInLogo from '../../public/svg/linkedInLogo.svg'
import TwitterLogo from '../../public/svg/twitterLogo.svg'
import YouTubeLogo from '../../public/svg/youTubeLogo.svg'
import { useEffect } from 'react'

const MemberMap = dynamic(() => import('components/MemberMap'), { ssr: false })

const Home = ({ data, posts }) => {
  const { locale } = useRouter()
  const { init, headLinks } = useTranslations()

  useEffect(() => {
    init()
  }, [])

  const parseTotalMembers = (string) => {
    if (string) {
      return string.replace(/{totalMembers}/g, data.totalMembers)
    }
  }

  return (
    <div>
      <Head>
        <title key="title">{process.env.NEXT_PUBLIC_SITE_TITLE}</title>
        <meta
          property="og:title"
          content={process.env.NEXT_PUBLIC_SITE_TITLE}
          key="og:title"
        />
        <link
          rel="canonical"
          href={process.env.NEXT_PUBLIC_DOMAIN}
          key="canonical"
        />
        <meta
          property="og:url"
          content={process.env.NEXT_PUBLIC_DOMAIN}
          key="og:url"
        />
        <meta property="og:type" content="website" key="og:type" />
        {data.mainImage && data.mainImage.asset && (
          <meta
            property="og:image"
            content={imageUrlBuilder(client)
              .image(data.mainImage)
              .width(1280)
              .url()}
            key="og:image"
          />
        )}
        {headLinks()}
      </Head>
      <section className="relative flex h-60 flex-col justify-center md:h-[500px] 2xl:h-[800px]">
        <Image
          src={imageUrlBuilder(client).image(data.mainImage).width(2560).url()}
          alt=""
          layout="fill"
          objectFit="cover"
          objectPosition="center"
          className="z-0 h-[800px]"
          priority={true}
        />
        <div className="absolute bottom-0 right-0 bg-primary-500/50 py-1 px-2 lg:right-1/2 lg:translate-x-1/2">
          {data.mainImageCredit && (
            <p className="text-xs text-primary-100">
              {data.mainImageCreditLink ? (
                <a
                  href={data.mainImageCreditLink}
                  target="_blank"
                  rel="noreferrer"
                  className="border-b border-primary-300 text-primary-300 hover:text-primary-200"
                >
                  {data.mainImageCredit}
                </a>
              ) : (
                data.mainImageCredit
              )}
            </p>
          )}
        </div>
        <div className="relative z-10 flex flex-col items-center">
          <div className="hidden md:block">
            <IdpcLogo width={300} height={300} fill="#FFFFFF" />
          </div>
          <div className="lg:max-w-md">
            <h1 className="text-center text-2xl font-bold text-white lg:text-3xl 2xl:text-6xl">
              {data.title}
            </h1>
            <ul className="flex flex-row justify-center">
              <li className="">
                <a
                  href={process.env.NEXT_PUBLIC_SOCIAL_FACEBOOK}
                  target="_blank"
                  rel="noreferrer"
                  className="block p-2"
                >
                  <FacebookLogo width={36} height={36} fill="#ffffff" />
                </a>
              </li>
              <li className="ml-4">
                <a
                  href={process.env.NEXT_PUBLIC_SOCIAL_TWITTER}
                  target="_blank"
                  rel="noreferrer"
                  className="block p-2"
                >
                  <TwitterLogo width={36} height={36} fill="#ffffff" />
                </a>
              </li>
              <li className="ml-4">
                <a
                  href={process.env.NEXT_PUBLIC_SOCIAL_LINKEDIN}
                  target="_blank"
                  rel="noreferrer"
                  className="block p-2"
                >
                  <LinkedInLogo width={36} height={36} fill="#ffffff" />
                </a>
              </li>
              <li className="ml-4">
                <a
                  href={process.env.NEXT_PUBLIC_SOCIAL_YOUTUBE}
                  target="_blank"
                  rel="noreferrer"
                  className="block p-2"
                >
                  <YouTubeLogo width={36} height={36} fill="#ffffff" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="bg-gradient-to-b from-[#EF8782] to-[#005B9B] py-10 md:py-24">
        <div className="container">
          <div className="text-center text-xl font-medium text-white md:text-2xl lg:mx-auto lg:mb-10 lg:max-w-xl lg:text-4xl">
            {data.intro}
          </div>
          <div className="flex flex-row flex-wrap justify-center">
            <div className="basis-1/2 px-2 text-center md:basis-1/3 ">
              <MembersIcon
                className="mx-auto h-16 w-16 text-white md:h-32 md:w-32"
                fill="#FFFFFF"
              />
              <p className="text-xs text-white md:text-base lg:text-2xl">
                <Trans>
                  {data.totalMembers ? data.totalMembers : `190+`} network
                  members in 75 countries
                </Trans>
              </p>
            </div>
            <div className="basis-1/2 px-2 text-center text-xs text-white md:basis-1/3 lg:text-base">
              <PublicationsIcon
                className="mx-auto h-16 w-16 text-white md:h-32 md:w-32"
                fill="#FFFFFF"
              />
              <p className="text-xs text-white md:text-base lg:text-2xl">
                <Trans>
                  {data.totalPublications
                    ? data.totalPublications.toLocaleString(locale)
                    : `2000+`}{' '}
                  IDPC Publications
                </Trans>
              </p>
            </div>
          </div>
          <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-4 md:grid-cols-2 md:gap-8">
            <ButtonLink
              href="/[[...slug]]"
              as={
                locale === 'en'
                  ? '/about'
                  : locale === 'fr'
                  ? '/a-propos'
                  : '/nosotros'
              }
              variant="secondary"
              locale={locale}
            >
              <span className="mr-2 leading-tight">
                <Trans>Find out more about us</Trans>
              </span>
              <FaArrowRight />
            </ButtonLink>
            <ButtonLink
              href="/publications/idpc"
              as="/publications/idpc"
              variant="secondary"
              locale={locale}
            >
              <span className="mr-2 leading-tight">
                <Trans>IDPC Publications</Trans>
              </span>
              <FaArrowRight />
            </ButtonLink>
          </div>
        </div>
      </section>
      <section className="py-6 md:py-24">
        <div className="container">
          <div className="grid grid-cols-1 gap-4 md:gap-8 lg:grid-cols-3 lg:gap-12 xl:gap-16">
            <div className="md:col-span-2">
              <H2>
                <Trans>Hot Topics</Trans>
              </H2>
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-8 lg:gap-10">
                {posts &&
                  !!posts.length &&
                  posts.map((post, i) => (
                    <PostTeaser
                      key={post._id}
                      post={post}
                      showType={true}
                      large={i === 0 ? true : false}
                    />
                  ))}
              </div>
            </div>
            <div className="md:col-span-2 lg:col-span-1">
              <H2>
                <Trans>Publications</Trans>
              </H2>
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-1 lg:gap-10">
                {data.publications &&
                  !!data.publications.length &&
                  data.publications.map((post) => (
                    <PostTeaser key={post._id} post={post} showType={true} />
                  ))}
              </div>
            </div>
          </div>
        </div>
        {data.news && (
          <div className="py-6">
            <div className="container">
              <H2>
                <Trans>Latest News & Events</Trans>
              </H2>
            </div>
            <IndexSlider posts={data.news} />
          </div>
        )}
        <div className="container">
          <div className="mt-10 grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-4">
            <ButtonLink href="/publications" as="/publications" locale={locale}>
              <span className="mr-2 leading-tight">
                <Trans>Publications</Trans>
              </span>
              <FaArrowRight />
            </ButtonLink>
            <ButtonLink href="/news" as="/news" locale={locale}>
              <span className="mr-2 leading-tight">
                <Trans>News</Trans>
              </span>
              <FaArrowRight />
            </ButtonLink>
            <ButtonLink href="/events" as="/events" locale={locale}>
              <span className="mr-2 leading-tight">
                <Trans>Events</Trans>
              </span>
              <FaArrowRight />
            </ButtonLink>
            <ButtonLink href="/blog" as="/blog" locale={locale}>
              <span className="mr-2 leading-tight">
                <Trans>Blog</Trans>
              </span>
              <FaArrowRight />
            </ButtonLink>
          </div>
        </div>
      </section>
      <section className="bg-accent-200 py-6 md:py-24">
        <div className="container">
          <h1 className="mb-10 text-center text-2xl font-bold lg:text-3xl 2xl:text-6xl">
            {parseTotalMembers(data.membersTitle)}
          </h1>
          <div className="mx-auto max-w-2xl leading-relaxed lg:text-xl 2xl:text-2xl">
            {parseTotalMembers(data.membersIntro)}
          </div>
          <MemberMap items={false} country={null} setCountry={() => false} />
          <div className="mt-10 flex justify-center">
            <ButtonLink href="/members" as="/members" locale={locale}>
              <span className="mr-2 leading-tight">
                <Trans>Explore IDPC Members</Trans>
              </span>
              <FaArrowRight />
            </ButtonLink>
          </div>
          <div>
            <Hr />
            <MemberHighlight />
          </div>
        </div>
      </section>
      {data.themes && data.themes.length && (
        <section className="bg-gradient-to-b from-[#EF8782] to-[#005B9B] py-6 md:py-24">
          <div className="container">
            <h1 className="mb-4 text-center text-2xl font-bold text-white lg:mb-10 lg:text-3xl 2xl:text-6xl">
              {data.themesTitle}
            </h1>
            <div className="mx-auto mb-4 max-w-2xl leading-relaxed text-white lg:text-xl 2xl:text-2xl">
              {data.themesIntro}
            </div>
            {data.themes &&
              data.themes.map((theme, i) => (
                <div
                  key={theme._key}
                  className={`flex flex-col py-4 text-xl text-white md:flex-row md:py-6 2xl:py-16`}
                >
                  <div
                    className={clsx(
                      `md:basis-1/3 lg:basis-1/2`,
                      i % 2 == 0 ? `md:pl-10 lg:pr-20` : `md:pr-10 lg:pl-20`
                    )}
                  >
                    <div className="relative mb-2 aspect-video w-full">
                      {theme.linksTo ? (
                        <Link
                          href="[[...slug]]"
                          as={`/${theme.linksTo.slug.current}`}
                          locale={theme.linksTo.locale}
                        >
                          <a className="block">
                            <Image
                              src={imageUrlBuilder(client)
                                .image(theme.mainImage)
                                .width(600)
                                .url()}
                              alt={theme.title}
                              layout="fill"
                              objectFit="cover"
                              objectPosition="center"
                            />
                          </a>
                        </Link>
                      ) : (
                        <Image
                          src={imageUrlBuilder(client)
                            .image(theme.mainImage)
                            .width(600)
                            .url()}
                          alt={theme.title}
                          layout="fill"
                          objectFit="cover"
                          objectPosition="center"
                        />
                      )}
                    </div>
                    {theme.mainImageCredit && (
                      <p className="mb-2 text-xs text-primary-100">
                        {theme.mainImageCreditLink ? (
                          <a
                            href={theme.mainImageCreditLink}
                            target="_blank"
                            rel="noreferrer"
                            className="border-b-2 border-accent-400 text-primary-100 hover:text-primary-200"
                          >
                            {theme.mainImageCredit}
                          </a>
                        ) : (
                          theme.mainImageCredit
                        )}
                      </p>
                    )}
                  </div>
                  <div
                    className={clsx(
                      `md:basis-2/3 lg:basis-1/2`,
                      i % 2 == 0
                        ? `md:pl-10 lg:pr-20 lg:pl-0`
                        : `md:pr-10 lg:pr-10 lg:pl-10`
                    )}
                  >
                    <h2 className="mb-4 text-xl font-bold 2xl:text-3xl">
                      {theme.linksTo ? (
                        <Link
                          href="[[...slug]]"
                          as={`/${theme.linksTo.slug.current}`}
                          locale={theme.linksTo.locale}
                        >
                          <a className="border-b-2 border-accent-500 text-white hover:text-accent-100">
                            {theme.title}
                          </a>
                        </Link>
                      ) : (
                        theme.title
                      )}
                    </h2>
                    <div className="text-base 2xl:text-xl">
                      <PortableText
                        value={theme.body}
                        components={defaultComponents}
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </section>
      )}
      {data.resources && !!data.resources.length && (
        <section className="py-6 md:py-24">
          <div className="container">
            <h1 className="mb-4 text-center text-2xl font-bold lg:mb-10 lg:text-3xl 2xl:text-6xl">
              {data.resourcesTitle}
            </h1>
            <div className="mx-auto mb-6 max-w-2xl leading-relaxed lg:mb-16 lg:text-xl 2xl:text-2xl">
              {data.resourcesIntro}
            </div>
            <div className="grid grid-cols-2 gap-6 py-6 xl:grid-cols-3">
              {data.resources.map((resource) => (
                <PostTeaser
                  key={resource._id}
                  post={resource}
                  excerpt={false}
                />
              ))}
            </div>
            <div className="flex justify-center lg:mt-10">
              <ButtonLink
                href="/[[...slug]]"
                as={
                  locale === 'en'
                    ? `/our-work/learning-centre`
                    : locale === 'fr'
                    ? `/notre-travail/centre-apprentissage`
                    : `/nuestro-trabajo/espacio-de-aprendizaje`
                }
                locale={locale}
              >
                <span className="mr-2 leading-tight">
                  <Trans>More resources at our Learning Centre</Trans>
                </span>
                <FaArrowRight />
              </ButtonLink>
            </div>
          </div>
        </section>
      )}
      {data.quotation && (
        <section>
          <div className="container">
            <Blockquote cite={data.quotationCitation} url={data.quotationUrl}>
              {data.quotation}
            </Blockquote>
          </div>
        </section>
      )}
    </div>
  )
}
export default Home
