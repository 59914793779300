/* eslint-disable @next/next/no-img-element */
import YouTube from 'react-youtube'
import getYouTubeID from 'get-youtube-id'
import imageUrlBuilder from '@sanity/image-url'
import client from '@lib/sanity'

export const defaultComponents = {
  types: {
    youtube: ({ value }) => {
      const { url } = value
      const id = getYouTubeID(url)
      return (
        <YouTube
          videoId={id}
          iframeClassName="absolute top-0 left-0 h-full w-full"
          className="pb-video relative h-0 w-full max-w-full overflow-hidden bg-slate-50"
        />
      )
    },
    image: ({ value }) => {
      if (!value?.asset?._ref) {
        return null
      }
      return (
        <div className="relative bg-white">
          <img
            alt={value.alt || ' '}
            src={imageUrlBuilder(client)
              .image(value)
              .width(826)
              .fit('fillmax')
              .auto('format')
              .url()}
          />
        </div>
      )
    },
    iframe: ({ value }) => {
      const { src } = value
      if (!src) {
        return null
      }
      return (
        <div className="aspect-w-16 aspect-h-9 bg-slate-50">
          <iframe src={src} />
        </div>
      )
    },
  },
}
