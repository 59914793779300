import Head from 'next/head'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { PortableText } from '@portabletext/react'
import imageUrlBuilder from '@sanity/image-url'
import client from '@lib/sanity'
import { removeDuplicates } from '@lib/utils'
import { formatDate, isSameDate } from '@lib/i18n'
import { defaultComponents } from '@lib/portableText'
import H1 from 'components/ui/H1'
import Header from 'components/ui/Header'
import { Trans } from '@lingui/macro'
import {
  FaLocationArrow,
  FaFileDownload,
  FaTags,
  FaIdCard,
  FaExternalLinkSquareAlt,
  FaMapMarkerAlt,
  FaGlobe,
  FaCalendarAlt,
} from 'react-icons/fa'
import IdpcLogo from '../../public/svg/idpcLogo.svg'
import ButtonLink from 'components/ButtonLink'
import { localeLabels } from '@lib/i18n'
import { absPostUrl } from '@lib/utils'
import { format } from 'date-fns'
import { Fragment, useEffect } from 'react'
import { useTranslations } from 'providers/TranslationsProvider'
import SidebarHeading from 'components/ui/SidebarHeading'
import SidebarSection from 'components/ui/SidebarSection'
import PostTypeLink from 'components/PostTypeLink'

const PostAuthor = ({ author }) => {
  const className =
    'text-sm md:text-base items-center border py-2 px-3 rounded bg-white flex flex-row !leading-none mb-2 mr-2 text-primary-400 hover:text-primary-600'
  switch (author._type) {
    case 'member':
    case 'teamMember':
      return (
        <div key={author._id}>
          <Link
            href={`/${author._type === 'member' ? 'members' : 'team'}/[slug]`}
            as={`/${author._type === 'member' ? 'members' : 'team'}/${
              author.slug.current
            }`}
          >
            <a className={`${className} border-accent-200`}>
              <div className="relative aspect-square w-8 border border-primary-200">
                <Image
                  src={imageUrlBuilder(client)
                    .image(author.mainImage)
                    .width(32)
                    .url()}
                  alt={author.name}
                  layout="fill"
                  objectFit="contain"
                  objectPosition={`center`}
                />
              </div>
              <span className="ml-2">{author.name}</span>
            </a>
          </Link>
        </div>
      )

    case 'person':
    case 'organisation':
    default:
      if (
        author._id === 'organisation_international-drug-policy-consortium-idpc'
      ) {
        return (
          <div key={author._id}>
            <Link href="/publications/idpc">
              <a className={`${className} border-accent-200`}>
                <IdpcLogo width={32} height={32} fill="#015B9B" />
                <span className="ml-2">{author.name}</span>
              </a>
            </Link>
          </div>
        )
      }
      return (
        <div className={className} key={author._id}>
          {author.name}
        </div>
      )
  }
}

const PostTranslationLink = ({ translation }) => {
  const publishedAt = new Date(translation.publishedAt)

  return (
    <Link
      href={`/[[...slug]]`}
      as={`/${translation.type}/${format(publishedAt, 'yyyy')}/${format(
        publishedAt,
        'MM'
      )}/${translation.slug.current}`}
      locale={translation.locale}
    >
      <a className="text-primary-400 hover:text-primary-600">
        <Trans id={localeLabels[translation.locale].id} />
      </a>
    </Link>
  )
}

const Post = ({ post }) => {
  const router = useRouter()
  const { init, headLinks } = useTranslations()

  useEffect(() => {
    init(post)
  }, [post])

  return (
    <article className="md:pb-24">
      <Head>
        <title key="title">
          {`${post?.title} - ${process.env.NEXT_PUBLIC_SITE_TITLE}`}
        </title>
        <meta property="og:title" content={post?.title} key="og:title" />
        <link rel="canonical" href={absPostUrl(post)} key="canonical" />
        <meta property="og:url" content={absPostUrl(post)} key="og:url" />
        <meta property="og:type" content="article" key="og:type" />
        {post.mainImage && post.mainImage.asset && (
          <meta
            property="og:image"
            content={imageUrlBuilder(client)
              .image(post.mainImage)
              .width(600)
              .url()}
            key="og:image"
          />
        )}
        {post.excerpt && post.excerpt !== '' && (
          <>
            <meta name="description" content={post.excerpt} key="description" />
            <meta
              property="og:description"
              content={post.excerpt}
              key="og:description"
            />
          </>
        )}
        {headLinks()}
      </Head>
      <div className="lg:hidden">
        {post.mainImage && post.mainImage.asset && (
          <div className="relative">
            <div className="relative aspect-video w-full bg-primary-100">
              <Image
                src={imageUrlBuilder(client)
                  .image(post.mainImage)
                  .width(600)
                  .url()}
                alt={post.title}
                layout="fill"
                objectFit="contain"
                objectPosition="center top"
                priority={true}
              />
            </div>
            {post.credit && (
              <p className="absolute bottom-0 right-0 bg-white bg-opacity-80 py-1 px-2 text-sm text-primary-400">
                {post.credit}
              </p>
            )}
          </div>
        )}
      </div>
      <div className="container">
        <Header>
          <p className="mb-4 text-center font-medium uppercase 2xl:mb-10">
            <PostTypeLink type={post.type} />
          </p>
          <H1 variant="smaller">{post.title}</H1>
          <div className="mt-4 text-center text-primary-300 md:mt-6 lg:mt-10">
            {post.publishedAt &&
              formatDate(
                new Date(post.publishedAt),
                'd MMMM yyyy',
                router.locale
              )}
          </div>
        </Header>
        <div className="content-start items-start justify-between md:flex md:flex-row">
          <div className="pb-10 md:basis-2/3 md:pr-10">
            {post.authors && !!post.authors.length && (
              <div className="mb-4 flex flex-row flex-wrap">
                {post.authors.map((author) => {
                  return (
                    author && <PostAuthor key={author._id} author={author} />
                  )
                })}
              </div>
            )}
            <div className="prose-primary-500 prose lg:prose-xl">
              <PortableText value={post.body} components={defaultComponents} />
            </div>
            {post.source && (
              <div className="my-4 flex md:my-10">
                <ButtonLink
                  href={post.source}
                  target="_blank"
                  variant="secondary"
                >
                  <FaExternalLinkSquareAlt />
                  <span className="ml-2">
                    <Trans>Read more</Trans>
                  </span>
                </ButtonLink>
              </div>
            )}
          </div>

          <div className="md:basis-1/3">
            {post.mainImage && post.mainImage.asset && (
              <div className="mb-10 hidden lg:block">
                <div className="relative aspect-video w-full bg-primary-100">
                  <Image
                    src={imageUrlBuilder(client)
                      .image(post.mainImage)
                      .width(600)
                      .url()}
                    alt={post.title}
                    layout="fill"
                    objectFit="cover"
                    objectPosition="center top"
                    priority={true}
                  />
                </div>
                {post.credit && (
                  <p className="mt-2 text-sm text-primary-300">{post.credit}</p>
                )}
              </div>
            )}
            {post.eventLocation && (
              <div className="mb-4 flex items-center rounded bg-accent-200 py-1 px-2">
                <FaLocationArrow className="mr-2 text-primary-400" />
                {post.eventLocation}
              </div>
            )}
            {post.eventStart &&
            post.eventEnd &&
            isSameDate(post.eventStart, post.eventEnd) ? (
              <div className="mb-4 flex items-center rounded bg-accent-200 py-1 px-2">
                <FaCalendarAlt className="mr-2 text-primary-400" />
                <span className="mr-2 block pt-0.5 text-sm uppercase text-primary-400">
                  <Trans>Date</Trans>
                </span>
                <span>
                  {formatDate(
                    new Date(post.eventStart),
                    'd MMMM yyyy',
                    router.locale
                  )}
                </span>
              </div>
            ) : (
              <>
                {post.eventStart && (
                  <div className="mb-4 flex items-center rounded bg-accent-200 py-1 px-2">
                    <FaCalendarAlt className="mr-2 text-primary-400" />
                    <span className="mr-2 block pt-0.5 text-sm uppercase text-primary-400">
                      <Trans>Start</Trans>
                    </span>
                    <span>
                      {formatDate(
                        new Date(post.eventStart),
                        'd MMMM yyyy',
                        router.locale
                      )}
                    </span>
                  </div>
                )}
                {post.eventEnd && (
                  <div className="mb-4 flex items-center rounded bg-accent-200 py-1 px-2">
                    <FaCalendarAlt className="mr-2 text-primary-400" />
                    <span className="mr-2 block pt-0.5 text-sm uppercase text-primary-400">
                      <Trans>End</Trans>
                    </span>
                    <span>
                      {formatDate(
                        new Date(post.eventEnd),
                        'd MMMM yyyy',
                        router.locale
                      )}
                    </span>
                  </div>
                )}
              </>
            )}
            {post.downloads && !!post.downloads.length && (
              <SidebarSection>
                <SidebarHeading>
                  <FaFileDownload className="mr-2" />
                  <Trans>Downloads</Trans>
                </SidebarHeading>
                <ul>
                  {post.downloads.map((download) => {
                    return (
                      download && (
                        <li key={download._key}>
                          <a
                            href={`${download.downloadUrl}?dl=`}
                            target="_blank"
                            rel="noreferrer"
                            className="text-primary-400 hover:text-primary-600"
                          >
                            {download.label}
                          </a>
                        </li>
                      )
                    )
                  })}
                </ul>
              </SidebarSection>
            )}
            {post.subjects && !!post.subjects.length && (
              <SidebarSection>
                <SidebarHeading>
                  <FaTags className="mr-2" />
                  <Trans>Topics</Trans>
                </SidebarHeading>
                <ul>
                  {removeDuplicates(post.subjects).map((subject) => {
                    return (
                      subject && (
                        <li key={subject._id}>
                          <Link
                            href="/subjects/[slug]"
                            as={`/subjects/${
                              subject.slug[router.locale].current
                            }`}
                          >
                            <a className="text-primary-400 hover:text-primary-600">
                              {subject.title[router.locale]}
                            </a>
                          </Link>
                        </li>
                      )
                    )
                  })}
                </ul>
              </SidebarSection>
            )}
            {post.regions && !!post.regions.length && (
              <SidebarSection>
                <SidebarHeading>
                  <FaMapMarkerAlt className="mr-2" />
                  <Trans>Regions</Trans>
                </SidebarHeading>
                <ul>
                  {post.regions.map((region) => {
                    return (
                      region && (
                        <li key={region._id}>
                          <Link
                            href="/regions/[slug]"
                            as={`/regions/${
                              region.slug[router.locale].current
                            }`}
                          >
                            <a className="text-primary-400 hover:text-primary-600">
                              {region.title[router.locale]}
                            </a>
                          </Link>
                        </li>
                      )
                    )
                  })}
                </ul>
              </SidebarSection>
            )}
            {post.relatedProfiles && !!post.relatedProfiles.length && (
              <SidebarSection>
                <SidebarHeading>
                  <FaIdCard className="mr-2" />
                  <Trans>Related Profiles</Trans>
                </SidebarHeading>
                <ul>
                  {removeDuplicates(post.relatedProfiles).map((profile) => {
                    return (
                      profile && (
                        <li key={profile._id}>
                          {profile._type === 'member' ||
                          profile._type === 'teamMember' ? (
                            <Link
                              href={`/${
                                profile._type === 'member' ? `members` : `team`
                              }/[[...slug]]`}
                              as={`/${
                                profile._type === 'member' ? `members` : `team`
                              }/${profile.slug.current}`}
                            >
                              <a className="text-primary-400 hover:text-primary-600">
                                {profile.name}
                              </a>
                            </Link>
                          ) : (
                            profile.name
                          )}
                        </li>
                      )
                    )
                  })}
                </ul>
              </SidebarSection>
            )}
            {post.translations && !!post.translations.length && (
              <SidebarSection>
                <SidebarHeading>
                  <FaGlobe className="mr-2" />
                  <Trans>Translations</Trans>
                </SidebarHeading>
                <ul className="grid grid-cols-2 md:grid-cols-1">
                  {post.translations.map((translation) => {
                    return (
                      translation && (
                        <Fragment key={translation._id}>
                          {translation && translation.locale !== post.locale && (
                            <li>
                              <PostTranslationLink translation={translation} />
                            </li>
                          )}
                        </Fragment>
                      )
                    )
                  })}
                </ul>
              </SidebarSection>
            )}
          </div>
        </div>
      </div>
    </article>
  )
}
export default Post
