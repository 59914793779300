import Link from 'next/link'
import { t } from '@lingui/macro'

const PostTypeLink = ({ type = 'news' }) => {
  let postType = t`News`
  const postTypes = {
    publications: t`Publications`,
    news: t`News`,
    blog: t`Blog`,
    events: t`Events`,
    media: t`IDPC in the Media`,
    press: t`Press Releases`,
  }
  if (postTypes[type]) {
    postType = postTypes[type]
  }
  return (
    <Link href={`/${type}`} as={`/${type}`}>
      <a>{postType}</a>
    </Link>
  )
}

export default PostTypeLink
