import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
} from 'react-share'
import { Trans } from '@lingui/macro'

const Share = ({ shareUrl, title }) => {
  return (
    <div className="my-10">
      <h6 className="mb-2 text-sm uppercase text-primary-400">
        <Trans>Share</Trans>
      </h6>
      <div>
        <div className="mr-4 inline-block text-center align-top">
          <FacebookShareButton
            url={shareUrl}
            quote={title}
            className="cursor-pointer"
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
        </div>
        <div className="mr-4 inline-block text-center align-top">
          <TwitterShareButton
            url={shareUrl}
            title={title}
            className="Demo__some-network__share-button"
          >
            <TwitterIcon size={32} round />
          </TwitterShareButton>
        </div>
        <div className="mr-4 inline-block text-center align-top">
          <LinkedinShareButton
            url={shareUrl}
            className="Demo__some-network__share-button"
          >
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
        </div>
        <div className="mr-4 inline-block text-center align-top">
          <WhatsappShareButton
            url={shareUrl}
            title={title}
            separator=":: "
            className="Demo__some-network__share-button"
          >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
        </div>
        <div className="mr-4 inline-block text-center align-top">
          <TelegramShareButton
            url={shareUrl}
            title={title}
            className="Demo__some-network__share-button"
          >
            <TelegramIcon size={32} round />
          </TelegramShareButton>
        </div>
      </div>
    </div>
  )
}

export default Share
