import { useRouter } from 'next/router'
import TextTruncate from 'react-text-truncate'
import imageUrlBuilder from '@sanity/image-url'
import Image from 'next/image'
import Link from 'next/link'
import { format } from 'date-fns'
import { formatDate } from '@lib/i18n'
import client from '@lib/sanity'
import { HiDocumentText } from 'react-icons/hi'
import {
  FaLocationArrow,
  FaCalendarAlt,
  FaAt,
  FaArrowRight,
} from 'react-icons/fa'
import PostType from 'components/PostType'

const PostLink = ({ post, children }) => {
  const publishedAt = new Date(post.publishedAt)

  return (
    <Link
      href="/[[...slug]]"
      as={`/${post.type}/${format(publishedAt, 'yyyy')}/${format(
        publishedAt,
        'MM'
      )}/${post.slug.current}`}
    >
      <a className="block">{children}</a>
    </Link>
  )
}

const PostCard = ({ post, showType = true }) => {
  const router = useRouter()

  return (
    <PostLink post={post}>
      <div className="flex h-64 w-64 flex-col overflow-hidden rounded border border-primary-100 bg-white shadow lg:w-full 2xl:h-80">
        {post.mainImage && (
          <div className="aspect-video">
            <div className="relative h-full w-full bg-primary-100">
              {post.mainImage.asset ? (
                <Image
                  src={imageUrlBuilder(client)
                    .image(post.mainImage)
                    .width(600)
                    .url()}
                  alt={post.title}
                  layout="fill"
                  objectFit="cover"
                  objectPosition="center"
                />
              ) : (
                <div className="content-stretch flex h-full items-center justify-center text-6xl text-primary-200">
                  <HiDocumentText />
                </div>
              )}
              {(post.eventLocation || post.eventStart) && (
                <div className="absolute top-2 right-2 flex flex-row flex-wrap justify-end">
                  {post.eventStart && (
                    <div className="mb-2 flex items-center rounded bg-accent-200 py-0.5 px-2 text-xs">
                      <FaCalendarAlt className="mr-2 text-primary-400" />
                      <span>
                        {formatDate(
                          new Date(post.eventStart),
                          'd MMMM yyyy',
                          router.locale
                        )}
                      </span>
                    </div>
                  )}
                  {post.eventLocation && (
                    <div className="mb-2 flex items-center rounded bg-accent-200 py-0.5 px-2 text-xs">
                      {post.eventLocation === 'Online' ||
                      post.eventLocation === 'Webinar' ? (
                        <FaAt className="mr-2 text-primary-400" />
                      ) : (
                        <FaLocationArrow className="mr-2 text-primary-400" />
                      )}
                      {post.eventLocation}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
        <div className="content-stretch flex h-1/2 flex-col justify-between">
          <h3 className="py-2 px-4 font-medium leading-tight 2xl:text-xl">
            <TextTruncate
              line={3}
              element="span"
              truncateText="…"
              text={post.title}
            />
          </h3>
          <div className="py-2 px-4 text-sm text-primary-300">
            {showType && (
              <span className="mr-1 hidden uppercase md:inline-block">
                <PostType post={post} />
              </span>
            )}
            {formatDate(
              new Date(post.publishedAt),
              'd MMMM yyyy',
              router.locale
            )}
          </div>
        </div>
      </div>
    </PostLink>
  )
}
export default PostCard
