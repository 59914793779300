import PageBlock from 'components/PageBlock'
import H2 from 'components/ui/H2'
import H3 from 'components/ui/H3'

const PageBlocks = ({ page }) => {
  if (!page?.pageBlocks?.length) return null

  const pageBlockSections = {
    default: {
      title: null,
      pageBlocks: [],
    },
  }
  page?.pageBlocks?.map((pageBlock) => {
    if (pageBlock.sectionTitle) {
      if (pageBlockSections[pageBlock.sectionTitle]) {
        pageBlockSections[pageBlock.sectionTitle].pageBlocks.push(pageBlock)
      } else {
        pageBlockSections[pageBlock.sectionTitle] = {
          title: pageBlock.sectionTitle,
          pageBlocks: [pageBlock],
        }
      }
    } else {
      pageBlockSections.default.pageBlocks.push(pageBlock)
    }
  })

  return (
    <div id="resources" className="pt-16">
      {page?.pageBlocksTitle && <H2>{page.pageBlocksTitle}</H2>}
      {Object.keys(pageBlockSections).map((key, i) => (
        <div
          key={`page-blocks-${pageBlockSections[key].title}`}
          className="pt-5"
        >
          {pageBlockSections[key].title && (
            <H3>{pageBlockSections[key].title}</H3>
          )}
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-3">
            {pageBlockSections[key].pageBlocks.map((block) => (
              <PageBlock key={block._key} block={block} />
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default PageBlocks
