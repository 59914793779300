import PostTeaser from 'components/PostTeaser'
import H2 from 'components/ui/H2'
import { useState, useEffect } from 'react'
import { ChevronDownIcon, XIcon } from '@heroicons/react/solid'
import { getClient } from '@lib/sanity'
import { useRouter } from 'next/router'
import { Trans } from '@lingui/macro'
import { FaArrowRight } from 'react-icons/fa'
import ButtonLink from 'components/ButtonLink'

const UNSearch = () => {
  const { locale } = useRouter()
  const [posts, setPosts] = useState([])
  const [terms, setTerms] = useState([])
  const [selectedTerms, setSelectedTerms] = useState([])
  const [showFilters, setShowFilters] = useState(false)

  useEffect(() => {
    getClient(false)
      .fetch(
        `*[_type == "subject" && references("subject_united-nations")] | order(lower(title.en) asc)`
      )
      .then((data) => {
        const termList = {
          'New York': [],
          Vienna: [],
          Geneva: [],
          'Cross-cutting issues': [],
        }
        data.map((item) => {
          if (item.section) {
            termList[item.section].push(item)
          }
        })
        setTerms(termList)
      })
      .catch(console.error)
  }, [])

  useEffect(() => {
    let refQuery =
      '*[_type == "subject" && references("subject_united-nations")]._id'
    if (selectedTerms.length) {
      refQuery = `["${selectedTerms.join('", "')}"]`
    }
    // console.log(selectedTerms)
    getClient(false)
      .fetch(
        `*[_type == "post" && locale == "${locale}" && references(${refQuery}) && ("organisation_international-drug-policy-consortium-idpc" in relatedProfiles[]._ref || "organisation_international-drug-policy-consortium-idpc" in authors[]._ref)] | score(sticky == true) | order(_score desc, publishedAt desc)[0...6]`
      )
      .then((data) => {
        // console.log(data)
        setPosts(data)
      })
      .catch(console.error)
  }, [locale, selectedTerms])

  const toggleTerm = (termId) => {
    let newSelected = selectedTerms.filter((item) => item !== termId)
    if (!selectedTerms.includes(termId)) {
      newSelected.push(termId)
    }
    setSelectedTerms(newSelected)
  }

  const handleClearTerms = (e) => {
    e.preventDefault()
    setSelectedTerms([])
  }

  const toggleShowFilters = (e) => {
    e.preventDefault()
    setShowFilters(!showFilters)
  }

  const renderTerms = () => {
    const output = []
    for (const section in terms) {
      output.push(
        <li key={section}>
          <strong>{section}</strong>
          <ul className="">
            {terms[section].map((sectionTerm) => (
              <li key={sectionTerm._id} className="">
                <a
                  onClick={() => toggleTerm(sectionTerm._id)}
                  className={`flex cursor-pointer flex-row items-center px-0 py-1 text-sm leading-tight ${
                    selectedTerms.includes(sectionTerm._id) ||
                    !selectedTerms.length
                      ? `font-medium`
                      : `text-primary-400`
                  }`}
                >
                  <span
                    className={`mr-1 inline-block h-2 w-2 shrink-0 rounded ${
                      selectedTerms.includes(sectionTerm._id) ||
                      !selectedTerms.length
                        ? `bg-accent-500`
                        : `bg-accent-200`
                    }`}
                  ></span>
                  {sectionTerm.title[locale]}
                </a>
              </li>
            ))}
          </ul>
        </li>
      )
    }
    return output
  }

  return (
    <div className="my-8">
      <div className="relative flex flex-col items-start md:flex-row">
        <div className="mb-8 w-full shrink-0 md:sticky md:top-20 md:w-1/2 lg:w-1/4">
          <H2>
            <Trans>Latest Items</Trans>
          </H2>
          <p className="mb-4 text-sm text-primary-300">
            <Trans>Select relevant topics below.</Trans>
          </p>
          <div className="md:hidden">
            <button
              onClick={toggleShowFilters}
              className="flex w-full flex-row items-center justify-between"
            >
              <span>Filter</span>
              {showFilters ? (
                <XIcon className="h-5 w-5" />
              ) : (
                <ChevronDownIcon className="h-5 w-5" />
              )}
            </button>
          </div>
          <div
            className={`${
              showFilters ? `block` : `hidden`
            } pt-4 md:block md:pr-8 md:pt-0`}
          >
            <ul>{renderTerms()}</ul>
            <div className="pt-4">
              <button
                onClick={handleClearTerms}
                className="flex items-center justify-center rounded-full border border-transparent bg-accent-400 px-2 py-0 text-center text-sm text-white hover:bg-accent-500 hover:text-white"
              >
                Clear filters
              </button>
            </div>
          </div>
        </div>
        <div>
          <div className="grid grid-cols-1 gap-4 md:gap-8 lg:grid-cols-2">
            {posts &&
              !!posts.length &&
              posts.map((post) => (
                <div key={post._id} className="">
                  <PostTeaser post={post} showType={true} />
                </div>
              ))}
          </div>
          <div className="mt-4 flex justify-center md:mt-8 lg:mt-10">
            <ButtonLink
              href={{
                pathname: '/search',
                query: {
                  subject: selectedTerms.length
                    ? selectedTerms
                    : `subject_united-nations`,
                },
              }}
            >
              <span className="mr-2 leading-tight">
                <Trans>Explore more content</Trans>
              </span>
              <FaArrowRight />
            </ButtonLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UNSearch
