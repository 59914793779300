import { useState, useEffect } from 'react'
import client, { getClient } from '@lib/sanity'
import imageUrlBuilder from '@sanity/image-url'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { toPlainText } from '@portabletext/react'
import TextTruncate from 'react-text-truncate'
import { Trans } from '@lingui/macro'
import ButtonLink from 'components/ButtonLink'
import { FaArrowRight } from 'react-icons/fa'

const MemberHighlight = () => {
  const { locale } = useRouter()
  const [member, setMember] = useState(null)

  useEffect(() => {
    getClient(false)
      .fetch(`*[_type == "member" && featured == true][0]`)
      .then((data) => setMember(data))
      .catch(console.error)
  }, [])

  if (!member) {
    return null
  }

  return (
    <div>
      <h2 className="my-4 text-center text-xl font-light uppercase md:text-2xl lg:my-10 lg:text-3xl">
        <Trans>Highlighted Member</Trans>
      </h2>
      <article className="md:pt-4">
        <div className="mx-auto flex max-w-4xl flex-col md:flex-row-reverse">
          {member.mainImage && (
            <div className="mb-4 shrink-0 md:basis-1/3">
              <Link
                href="/members/[slug]"
                as={`/members/${member.slug.current}`}
              >
                <a className="block font-medium">
                  <div className="relative mx-auto aspect-square w-32 md:w-full">
                    <Image
                      src={imageUrlBuilder(client)
                        .image(member.mainImage)
                        .width(320)
                        .url()}
                      alt={member.name}
                      layout="fill"
                      priority={true}
                    />
                  </div>
                </a>
              </Link>
            </div>
          )}
          <div className="md:basis-2/3">
            <h1 className="mb-4 text-center text-2xl md:text-left lg:text-3xl">
              <Link
                href="/members/[slug]"
                as={`/members/${member.slug.current}`}
              >
                <a className="block font-medium">{member.name}</a>
              </Link>
            </h1>
            <div className="md:pr-10 lg:text-xl">
              <div className="mb-4">
                <TextTruncate
                  line={5}
                  element="div"
                  truncateText="…"
                  text={toPlainText(member.body[locale])}
                />
              </div>
              <div className="flex justify-center md:justify-start">
                <ButtonLink
                  href="/members/[slug]"
                  as={`/members/${member.slug.current}`}
                  variant="secondary"
                >
                  <span className="mr-2">
                    <Trans>Read more</Trans>
                  </span>
                  <FaArrowRight />
                </ButtonLink>
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
  )
}
export default MemberHighlight
